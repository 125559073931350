$(document).ready(function() {
  // AOS.init({
  //   duration: 1000,
  //   easing: 'ease-in-out',
  //   once: true,
  // });

  $('a').smoothScroll();

  $(window).scroll(function() {
    var $height = $(window).scrollTop();

    if ($height > 50) {
      $('.fixed_bottom').show();
    } else {
      $('.fixed_bottom').hide();
    }
  });
  $('.menu-trigger').on('click', function() {
    $(this).toggleClass('active');
    $('.g-nav').slideToggle();
  });
  $('.g-nav ul li a').on('click', function() {
    $('.menu-trigger').toggleClass('active');
    $('.g-nav').slideToggle();
  });

  $('.faq_one').on('click', function() {
    $(this).toggleClass('open');
    $(this)
      .children('.ans')
      .slideToggle();
  });
});
